import React, { useEffect, useRef, useState } from "react";
import { Fab, IconButton, Box, TextField, Button } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import ExpandIcon from "@mui/icons-material/NorthWest";
import CollapseIcon from "@mui/icons-material/SouthEast";
import { styled } from "@mui/system";
import TextContainer from "../Texts/TextContainer";
import colors from "../../global/colors";
import useChatBot from "../../api/chatbot.api";
import useNotification from "../notification";
import ReactMarkdown from "react-markdown";
import remarkGF from "remark-gfm";
const RotateFab = styled(Fab)(({ rotate }) => ({
  transition: "transform 0.3s ease",
  transform: rotate ? "rotate(360deg)" : "rotate(0deg)",
}));

const ChatBox = styled(Box)(({ full }) => ({
  position: "absolute",
  bottom: "4rem",
  right: "1rem",
  borderTopLeftRadius: "1rem",
  paddingBottom: "1rem !important",
  borderTopRightRadius: "1rem",
  borderBottomLeftRadius: "1rem",
  padding: "0",
  minHeight: full ? "70vh" : "55vh",
  maxHeight: full ? "70vh" : "55vh",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: !full ? "30vw" : "80vw",
  minWidth: "300px",
  overflowY: "auto",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
  zIndex: 100000,
}));

export default function ChatButton() {
  const [open, setOpen] = useState(false);
  const [full, setFull] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [inputValue, setInputValue] = useState("");
  const chatBoxRef = useRef(null);

  const { newMessage: newMessageApi } = useChatBot();
  const { toastError } = useNotification();
  const handleClick = () => {
    setRotate(!rotate);
    setOpen(!open);
  };
  const handleClose = () => {
    setRotate(false);
    setOpen(false);
    setFull(false);
  };
  const handleSendMessage = () => {
    if (inputValue.trim()) {
      setMessages((prev) => [
        ...messages,
        {
          id: prev.length + 1,
          type: "human",
          content: inputValue,
        },
      ]);
      setInputValue("");
      newMessageApi(inputValue, [])
        .then(async (stream) => {
          let token = "";
          for await (const chunk of stream) {
            token += chunk;
            setNewMessage(token);
          }
          setNewMessage("");
          setMessages((prev) => [
            ...prev,
            {
              id: prev.length + 1,
              type: "ai",
              content: token,
            },
          ]);
        })
        .catch((err) => {
          console.error(err);
          setNewMessage("");
          toastError("somthing happen wrong please try again");
        });
    }
  };

  useEffect(() => {
    // if (chatBoxRef.current) {
    //   console.log("heare to scrool to bottom");
    //   chatBoxRef.current.scrollIntoView({ behavior: "smooth" });
    // }
    const messagesBox = document.getElementById("messages-box");
    if (messagesBox) {
      messagesBox.scrollTop = messagesBox.scrollHeight;
    }
  }, [messages?.length, newMessage, chatBoxRef]);

  const handleClickOutside = (event) => {
    if (chatBoxRef.current && !chatBoxRef.current.contains(event.target)) {
      handleClose();
    }
  };

  const handleClickEnter = (event) => {
    if (event.key === "Escape") {
      return handleClose();
    }
  };

  // console.log(newMessage);
  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleClickEnter);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleClickEnter);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleClickEnter);
    };
  }, [open]);
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: "2rem",
        right: "2rem",
      }}
    >
      <Box position="relative">
        <RotateFab color="primary" onClick={handleClick} rotate={rotate}>
          <ChatIcon />
        </RotateFab>
        {open && (
          <ChatBox ref={chatBoxRef} bgcolor={colors.white} full={full}>
            <IconButton
              sx={{
                position: "absolute",
                color: colors.white,
                top: ".1rem",
                right: "1rem",
              }}
              edge="end"
              color="#fff"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <TextContainer
              width="100%"
              padding="1rem"
              color={colors.white}
              style={{ background: colors.darkBlue }}
            >
              PharamaCanadaBot
            </TextContainer>
            <IconButton
              sx={{
                position: "absolute",
                color: colors.white,
                top: ".1rem",
                left: "1rem",
              }}
              edge="end"
              color="#fff"
              onClick={() => setFull(!full)}
            >
              {full ? <CollapseIcon /> : <ExpandIcon />}
            </IconButton>
            <Messages id="messages-box">
              {messages.map((msg, index) => (
                <Message
                  sx={{
                    bgcolor:
                      msg.type === "ai" ? colors.lightBlue : colors.lightGray,
                    borderBottomLeftRadius: msg.fromChat === "ai" ? 0 : "",
                    borderBottomRightRadius: msg.fromChat === "human" ? 0 : "",
                    alignSelf: msg.fromChat ? "end" : "start",
                  }}
                  key={msg.id}
                  boxShadow={1}
                >
                  <ReactMarkdown remarkPlugins={[remarkGF]}>
                    {msg.content}
                  </ReactMarkdown>
                </Message>
              ))}
              {newMessage?.length ? (
                <Message
                  sx={{
                    bgcolor: colors.lightBlue,
                    borderBottomLeftRadius: "",
                    borderBottomRightRadius: 0,
                    alignSelf: "end",
                  }}
                  key={-1}
                  boxShadow={1}
                >
                  {newMessage}
                </Message>
              ) : null}
            </Messages>
            <Box
              width="100%"
              alignItems="start"
              display="flex"
              padding="0 .5rem"
            >
              <TextField
                variant="outlined"
                size="small"
                multiline
                minRows={1}
                maxRows={2}
                fullWidth
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Type a message..."
                sx={{ flexGrow: 1, marginRight: "0.5rem" }}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    if (e.shiftKey) return;
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
              />
              <Button
                variant="contained"
                sx={{ height: "max-content !important" }}
                onClick={handleSendMessage}
              >
                Send
              </Button>
            </Box>
          </ChatBox>
        )}
      </Box>
    </Box>
  );
}

const Messages = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: ".7rem",
  padding: ".5rem 1.5rem",
  height: "100%",
  minHeight: "35vh",
  maxHeight: "35vh",
  marginBottom: "1rem",
  overflowY: "auto",
});

const Message = styled(Box)({
  borderRadius: "0.5rem",
  padding: "0.5rem .7rem",
  width: "fit-content",
  background: colors.lightBlue,
  fontSize: "1rem",
});
