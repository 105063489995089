import React from "react";
import Navbar from "../../components/NavBar";
import MainFrame from "../../components/MainFrame";
import { useLocation } from "react-router-dom";
import AilmentsAlgorithm from "./algorithm";
import styled from "styled-components";
import { Box, Divider } from "@mui/material";
import colors from "../../global/colors";
import TextContainer from "../../components/Texts/TextContainer";
import { TOPIC } from "../../components/Texts/styles";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MedicationLiquidIcon from "@mui/icons-material/MedicationLiquid";
import AirlineSeatIndividualSuiteIcon from "@mui/icons-material/AirlineSeatIndividualSuite";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import TableChartIcon from "@mui/icons-material/TableChart";
import { DrugTable } from "./Table";
import Data from "../../constants/ailments.db.json";
import { TherapyMonitoring } from "./monitoringOfTherapy";
import Descriptions from "./Descriptions";
const Container = styled(Box)({
  maxHeight: "78vh",
  overflow: "auto",
  padding: "0 1rem",
  paddingBottom: "1rem",
  borderRadius: "5px",
  background: colors.white,
});
const TitleDivider = styled(Divider)({
  "&::before": {
    width: "1%",
    borderTop: `2px dashed ${colors.lightBlue}`,
  },
  "&::after": {
    borderTop: `2px dashed ${colors.lightBlue}`,
  },
  margin: "1rem 0",
  textAlign: "left",
});
function Ailments() {
  const location = useLocation();
  const ailment = location.state;
  const { goalsOfTherapy = [], nonPharma = [], pharma = [] } = Data[ailment];
  return (
    <React.Fragment>
      <Navbar topic={ailment} />
      <Container>
        <TitleDivider>
          <TextContainer
            textWrap="nowrap !important"
            {...TOPIC}
            color={colors.darkBlue}
          >
            <AirlineSeatIndividualSuiteIcon
              sx={{ marginRight: ".5rem", width: 20 }}
            />
            Goals of Therapy
          </TextContainer>
        </TitleDivider>
        <Descriptions items={goalsOfTherapy} listItemStyle={true} />
        <TitleDivider>
          <TextContainer
            textWrap="nowrap !important"
            {...TOPIC}
            color={colors.darkBlue}
          >
            <AirlineSeatIndividualSuiteIcon
              sx={{ marginRight: ".5rem", width: 20 }}
            />
            Nonpharmacologic Therapy
          </TextContainer>
        </TitleDivider>
        <Descriptions items={nonPharma} />
        <TitleDivider>
          <TextContainer
            textWrap="nowrap !important"
            {...TOPIC}
            color={colors.darkBlue}
          >
            <MedicationLiquidIcon sx={{ marginRight: ".5rem", width: 20 }} />
            Pharmacologic Therapy
          </TextContainer>
        </TitleDivider>
        <Descriptions items={pharma} />
        <TitleDivider>
          <TextContainer
            textWrap="nowrap !important"
            {...TOPIC}
            color={colors.darkBlue}
          >
            <MonitorHeartIcon sx={{ marginRight: ".5rem", width: 20 }} />
            Monitoring of Therapy
          </TextContainer>
        </TitleDivider>
        <TherapyMonitoring ailment={ailment} />
        {/* <TitleDivider>
          <TextContainer
            textWrap="nowrap !important"
            {...TOPIC}
            color={colors.darkBlue}
          >
            <AccountTreeIcon sx={{ marginRight: ".5rem", width: 20 }} />
            Algorithms
          </TextContainer>
        </TitleDivider> */}
        {/* <AilmentsAlgorithm ailment={ailment} /> */}
        <TitleDivider>
          <TextContainer
            textWrap="nowrap !important"
            {...TOPIC}
            color={colors.darkBlue}
          >
            <TableChartIcon sx={{ marginRight: ".5rem", width: 20 }} />
            Drug Table
          </TextContainer>
        </TitleDivider>
        <DrugTable ailment={ailment} />
      </Container>
    </React.Fragment>
  );
}

export default MainFrame(Ailments, true);
