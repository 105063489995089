import { Paper, TableCell, TableRow } from "@mui/material";
import React, { Fragment, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import Footer, {
  CustomTableBody,
  CustomTableContainer,
  TableHeader,
} from "../../components/table";
import Table, { Row } from "../../global/table";
import Filter from "../../components/filter/filter";
import MainFrame from "../../components/MainFrame";
import useRequestApi from "../../api/request.api";
import moment from "moment";
// import useRequestApi from "../../api/requests.api";

const filters = [{ label: "Title", type: "name", collection: "title" }];

function Requests() {
  const [requests, setRequests] = React.useState([]);
  const navigate = useNavigate();
  // const api = useRequestApi();
  const api = useRequestApi();
  const [query, setQuery] = React.useState({
    page: 0,
    size: 20,
  });
  const loadRequests = useCallback((query) => {
    let isActive = true;
    api
      .getRequests(query)
      .then((result) => {
        if (isActive) setRequests(result);
      })
      .catch((err) => console.log(err));
    return () => {
      isActive = false;
    };
  }, []);
  useEffect(loadRequests, [query, loadRequests]);

  //   function handleOpenModal(type, ...args) {
  //     if (type === "cancle") {
  //       setContent({
  //         title: "cancel request",
  //         body: (
  //           <CancleModal
  //             requestId={args[0]}
  //             onCancle={() => loadRequests(query)}
  //           />
  //         ),
  //       });
  //     }
  //     setShow(true);
  //   }
  return (
    <Fragment>
      <NavBar topic="Requests" />
      <Filter convertor={"toSQL"} filters={filters} dataLoader={loadRequests} />
      <CustomTableContainer component={Paper} style={{ maxHeight: "73vh" }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHeader>
            <TableRow>
              <TableCell>service</TableCell>
              <TableCell>state</TableCell>
              <TableCell>from</TableCell>
              <TableCell width="15%">date</TableCell>
              {/* <TableCell width="7%">Action</TableCell> */}
            </TableRow>
          </TableHeader>
          <CustomTableBody>
            {requests?.map((request, index) => {
              return (
                <Row
                  style={{ cursor: "pointer" }}
                  key={index}
                  onClick={() => {
                    // navigate("/Requests/Request:name",
                    navigate(`/requests/request/${request._id}`, {
                      state: request,
                    });
                  }}
                >
                  <TableCell style={{ padding: "10px", fontSize: ".9rem" }}>
                    {request?.service}
                  </TableCell>
                  <TableCell style={{ padding: "10px", fontSize: ".9rem" }}>
                    {request.state}
                  </TableCell>
                  <TableCell style={{ padding: "10px", fontSize: ".9rem" }}>
                    {request?.firstName} {request?.lastName}
                  </TableCell>
                  <TableCell style={{ padding: "10px", fontSize: ".9rem" }}>
                    {moment(request.createdAt).format("YYYY MM DD")}
                  </TableCell>
                  {/* <TableCell style={{ padding: "10px", fontSize: ".9rem" }}>
                    {request.state === 0 ? (
                      <DeleteIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenModal("cancle", request.id);
                        }}
                      />
                    ) : null}
                  </TableCell> */}
                </Row>
              );
            })}
          </CustomTableBody>
        </Table>
        <Footer
          value={query}
          handleChange={(e) => {
            setQuery({
              ...query,
              ...e,
            });
          }}
        />
      </CustomTableContainer>
    </Fragment>
  );
}

export default MainFrame(Requests, true);
