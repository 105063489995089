import { useEffect, useState } from "react";

export default function useChatBot() {
  const [session, setSession] = useState();

  useEffect(() => {
    //get sessio from server
    fetch("/apiv1/chat-bot/session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.sessionId) setSession(res.sessionId);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  function readChunks(reader) {
    const textDecoder = new TextDecoder("utf-8");
    return {
      async *[Symbol.asyncIterator]() {
        let readResult = await reader.read();
        while (!readResult.done) {
          yield textDecoder.decode(readResult.value);
          readResult = await reader.read();
        }
      },
    };
  }

  const newMessage = async (question) => {
    if (!session) return;

    const res = await fetch("/apiv1/chat-bot/messages", {
      method: "POST",
      body: JSON.stringify({ question, sessionId: session }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    return readChunks(res.body.getReader());
  };

  return { newMessage };
}
