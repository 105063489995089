import { createSlice } from "@reduxjs/toolkit";
import questions from "../../pages/forms/components/questions";
const initialState = {
  stack: [],
  data: {
    metadata: {
      ABULI: "ABULI",
      DIN: "DIN",
      N_ORDER: "N_ORDER",
      STATUS: "STATUS",
      dBefore: new Date(),
      eDate: new Date(),
      dQ: "dQ",
      dDS: "dDS",
      pQ: "pQ",
      dS: "dS",
      route: "route",
      refilles: "refilles",
    },
  },
  patient: {},
  withPatient: false,
  finish: false,
};
export const formSlice = createSlice({
  name: "form",
  initialState: { ...initialState },
  reducers: {
    setForm: (state, action) => {
      return {
        ...initialState,
        ...action.payload,
      };
    },

    push: (state, action) => {
      return {
        ...state,
        stack: [
          ...state.stack,
          {
            formId: action.payload.formId,
            resolver: action.payload.resolver,
          },
        ],
        finish: action.payload?.finish ? action.payload.finish : false,
      };
    },

    pop: (state, action) => {
      return {
        ...state,
        stack: state.stack.slice(0, -1),
        finish: false,
      };
    },
    clear: (state, action) => {
      return { ...initialState };
    },
    updateFormData: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.formId]: action.payload.formData,
        },
      };
    },
    setPatient: (state, action) => {
      const ownerInfo = localStorage.getItem("userInfo");
      if (!action.payload?.navigate) {
        return {
          ...state,
          data: {
            ...state.data,
            patient: action.payload,
            owner: JSON.parse(ownerInfo?.length ? ownerInfo : {}),
            createdAt: new Date().toString(),
          },
        };
      }
      const question = questions[action.payload.illness];
      if (!question) {
        console.error("invalid ailment");
        return;
      }
      const resolver = question["index"].type;
      return {
        ...state,
        stack: [
          {
            formId: "index",
            resolver,
          },
        ],
        data: {
          ...state.data,
          createdAt: new Date().toString(),
          patient: action.payload.patient,
          owner: JSON.parse(ownerInfo?.length ? ownerInfo : {}),
        },
        finish: false,
      };
    },
    finish: function (state) {
      return {
        ...state,
        finish: true,
      };
    },
    unFinish: function (state) {
      return {
        ...state,
        finish: false,
      };
    },
    navigateTo: function (state, action) {
      const index = action.payload.index;
      if (index >= 0) {
        return {
          ...state,
          stack: state.stack.slice(0, index + 1),
          finish: false,
        };
      }
    },
  },
});
export const FormActiosn = formSlice.actions;
export default formSlice.reducer;
