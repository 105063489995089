import { useMediaQuery } from "@material-ui/core";
import colors from "../../global/colors";
import DrawerMenu from "../menu";
import styled from "@emotion/styled";
import ChatButton from "./chatButton";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${colors.lightBgGray0};
`;
const ContentContainer = styled.div`
  position: relative;
  top: 0;
  height: 100%;
  width: 100%;
`;

export default (Component) => (props) => {
  const isSmallScreen = useMediaQuery("(min-width:388px)");
  return (
    <Container>
      {isSmallScreen ? (
        <ContentContainer>
          <DrawerMenu>
            <Component {...props} />
          </DrawerMenu>
            <ChatButton/>
        </ContentContainer>
      ) : (
        <ContentContainer>
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            {" "}
            &#x1F44B;please enlarge the screen!
          </p>
        </ContentContainer>
      )}
    </Container>
  );
};
