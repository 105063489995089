import { createSlice } from "@reduxjs/toolkit";
import Data from "../../constants/ailments.db.json";
const initialState = {
  alg: {},
  ailment: "",
  patient: undefined,
  questions: {
    referredToDoctor: {
      answer: false,
      comment: "",
    },
    medicationsPrescribed: {
      answer: false,
      comment: "",
    },
  },
  medications: [],
  metadata: {
    ABULI: "ABULI",
    DIN: "DIN",
    N_ORDER: "N_ORDER",
    STATUS: "STATUS",
    dBefore: new Date(),
    eDate: new Date(),
    dQ: "dQ",
    dDS: "dDS",
    pQ: "pQ",
    dS: "dS",
    route: "route",
    refilles: "refilles",
  },
};
function generateSelectedList(state, id) {
  let selectedItems = [];
  let temp = [id];
  const items = Data[state?.ailment]?.items;
  console.log(items);
  if (!items) return [];

  while (temp.length) {
    const nodeId = temp.pop();
    if (selectedItems.includes(nodeId)) continue;
    else selectedItems.push(nodeId);
    const node = items?.entities[nodeId];
    if (!node?.type) continue;
    if (node.type === "SIMPLE") temp = [...temp, node.selectedItems];
    if (node.type === "MU-Q" && node?.selectedItems) {
      const { items = [] } = state.alg[nodeId] ?? {};
      if (items.length && !!node?.selectedItems?.yes)
        temp = [...temp, node?.selectedItems?.yes];
      else if (!!node?.selectedItems?.no)
        temp = [...temp, node?.selectedItems?.no];
    }
  }
  return selectedItems;
}
export const AlgSlice = createSlice({
  name: "alg",
  initialState,
  reducers: {
    setAlg: function (state, action) {
      return {
        ...initialState,
        ...action.payload,
      };
    },
    updateAlg: function (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateAlgNode: function (state, action) {
      const { id, data } = action.payload;
      const prevSelectedItems = generateSelectedList(state, id).filter(
        (item) => item !== id
      );
      const prevItemsLength = state.alg[id]?.items?.length ?? 0;

      if (id in state.alg) {
        state.alg[id] = { ...state.alg[id], ...data };
      } else {
        state.alg[id] = data;
      }

      if (
        data?.items &&
        ((prevItemsLength === 0 && data?.items?.length !== 0) ||
          (prevItemsLength !== 0 && data?.items?.length === 0))
      ) {
        const selectedItems = generateSelectedList(state, id).filter(
          (item) => item !== id
        );
        selectedItems.forEach((item) => {
          if (item in state.alg) {
            state.alg[item].isSelected = true;
          } else {
            state.alg[item] = {
              isSelected: true,
            };
          }
        });
        prevSelectedItems.forEach((item) => {
          if (item in state.alg) {
            state.alg[item].isSelected = false;
          } else {
            state.alg[item] = {
              isSelected: false,
            };
          }
        });
      }
    },
    selectNode: function (state, action) {
      const { id, select } = action.payload;
      const selectedItems = generateSelectedList(state, id);
      console.log(selectedItems);
      selectedItems.forEach((item) => {
        if (item in state.alg) {
          state.alg[item].isSelected = select;
        } else {
          state.alg[item] = {
            isSelected: select,
          };
        }
      });
    },
  },
});
export const getAlgNode = (nodeId) => (state) => {
  const node = state.alg.alg[nodeId];
  return node ?? {};
};
export const AlgActions = AlgSlice.actions;
export default AlgSlice.reducer;
