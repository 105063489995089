import { Fragment, useCallback, useContext, useState } from "react";
import MainFrame from "../../components/MainFrame";
import NavBar from "../../components/NavBar";
import { List, ListItemIcon, ListItemText } from "@mui/material";
import TextContainer from "../../components/Texts/TextContainer";
import colors from "../../global/colors";
import TitleIcon from "@mui/icons-material/Title";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Btn } from "../../components/Buttons/styles";
import { modalContext } from "../../components/modal/modal";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { ChangeStateModal } from "../forms/components/progressbar/modal";
import Multistepprogressbar from "../forms/components/progressbar/multistepprogressbar";
import useRequestApi from "../../api/request.api";
import { handleDownload } from "../../utils/downloadFile";
// cimport useRequestApi from "../../api/requests.api";

function Request() {
  const { state } = useLocation();
  const [activeStep, setActiveStep] = useState(state.state);
  const { setShow, setContent } = useContext(modalContext);
  const api = useRequestApi();
  const setRequestState = useCallback(
    (step) => {
      api
        .changeRequestState(state._id, step)
        .then(() => {
          setActiveStep(step);
        })
        .catch((err) => console.log(err));
    },
    [state]
  );
  function handleOpenModal(type, step, stepLabel) {
    // if (type === "NextStep") {
    console.log("show the modal");
    setContent({
      title: `go to state ${stepLabel}`,
      body: (
        <ChangeStateModal
          step={step}
          stepLabel={stepLabel}
          GoToState={() => {
            setRequestState(stepLabel);
          }}
        />
      ),
    });
    // }
    setShow(true);
  }
  const nextStep = (step, label) => {
    const stepMapper = {
      idle: 0,
      review: 1,
      in_progress: 2,
      sent: 3,
    };
    const prev = stepMapper[activeStep];
    if (step > prev) {
      handleOpenModal("NextStep", step, label);
    }
  };
  return (
    <Fragment>
      <NavBar topic="Request title">
        {activeStep === "idle" && (
          <Btn variant="contained" onClick={() => nextStep(1, "review")}>
            Accept
          </Btn>
        )}
      </NavBar>
      <List
        style={{
          width: "100%",
          background: colors.white,
          padding: "1rem",
          borderRadius: ".5rem",
          maxHeight: "75vh",
          overflow: "auto",
          boxShadow: colors.boxShadow,
        }}
      >
        <TextContainer margin="0 0 .5rem 0">
          <ListItemIcon>
            <TitleIcon />
          </ListItemIcon>
          <ListItemText primary="service" style={{ fontSize: "1.1rem" }} />
          <TextContainer
            textAlign="justify"
            fontSize="1rem"
            maxWidth="70%"
            color={colors.darkBlue}
          >
            {state.service}
          </TextContainer>
        </TextContainer>
        <TextContainer margin="0 0 .5rem 0">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="from" style={{ fontSize: "1.1rem" }} />
          <TextContainer
            textAlign="justify"
            fontSize="1rem"
            maxWidth="70%"
            color={colors.darkBlue}
          >
            {state.firstName} {state.lastName}
          </TextContainer>
        </TextContainer>
        <TextContainer margin="0 0 .5rem 0">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="phone" style={{ fontSize: "1.1rem" }} />
          <TextContainer
            textAlign="justify"
            fontSize="1rem"
            maxWidth="70%"
            color={colors.darkBlue}
          >
            {state.phone}
          </TextContainer>
        </TextContainer>
        <TextContainer margin="0 0 .5rem 0">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="email" style={{ fontSize: "1.1rem" }} />
          <TextContainer
            textAlign="justify"
            fontSize="1rem"
            maxWidth="70%"
            color={colors.darkBlue}
          >
            {state.email}
          </TextContainer>
        </TextContainer>
        <TextContainer margin="0 0 .5rem 0">
          <ListItemIcon>
            <CalendarMonthIcon />
          </ListItemIcon>
          <ListItemText primary="Date" style={{ fontSize: "1.1rem" }} />
          <TextContainer
            textAlign="justify"
            fontSize="1rem"
            maxWidth="70%"
            color={colors.darkBlue}
          >
            {dayjs(state.createdAt).format("YYYY-MM-DD")}
          </TextContainer>
        </TextContainer>
        <TextContainer margin="0 0 .5rem 0">
          <ListItemIcon>
            <TextSnippetIcon />
          </ListItemIcon>
          <ListItemText primary="description" style={{ fontSize: "1.1rem" }} />
          <TextContainer
            textAlign="justify"
            fontSize="1rem"
            maxWidth="70%"
            color={colors.darkBlue}
          >
            {state.desc}
          </TextContainer>
        </TextContainer>
        {state?.fileAttach && (
          <TextContainer margin="0 0 .5rem 0">
            <ListItemIcon>
              <TextSnippetIcon />
            </ListItemIcon>
            <ListItemText primary="attachment" style={{ fontSize: "1.1rem" }} />
            <TextContainer
              textAlign="justify"
              fontSize="1rem"
              maxWidth="70%"
              cursor="pointer"
              color={colors.darkBlue}
              onClick={() => {
                if (state.fileAttach) {
                  handleDownload(
                    state.fileAttach.fileName,
                    state.fileAttach.path
                  );
                }
              }}
            >
              {state?.fileAttach?.fileName}
            </TextContainer>
          </TextContainer>
        )}
        {/* 
            show attach file
        */}
        <Multistepprogressbar
          steps={["idle", "accept", "sent"]}
          nextStep={nextStep}
          activeStep={activeStep}
          skipp={false}
          previous={false}
          next={true}
          reset={false}
        />
        &nbsp;
      </List>
    </Fragment>
  );
}
export default MainFrame(Request);
