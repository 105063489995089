import useApi from "./httpService";

export default function useRequestApi() {
  const api = useApi();

  const getRequests = async (query) => {
    const result = await api({
      method: "GET",
      url: "/requests",
      params: query,
    });
    return result.data;
  };

  const changeRequestState = async (requestId, state) => {
    await api({
      method: "PUT",
      url: `/requests/${requestId}`,
      data: { state },
    });
  };

  return {
    getRequests,
    changeRequestState,
  };
}
